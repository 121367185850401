<template lang="pug">
div
  .page-header
    .row
      .col-md-6
        h1 Agenda
      .col-md-5.col-md-offset-1
          .text-right
            router-link(to='/sistema/clientes/novo').btn.btn-success Novo Cliente
  form
    .row
      .col-md-4
        label Clientes:
        input.form-control(v-model='filtro.cliente', placeholder='Clientes')
      .col-md-2
        label Data:
        flat-pickr.col-xs-10.col-sm-3(
          v-model='filtro.dataagenda',
          :config='config',
          class='form-control',
          placeholder='Data',
          name='date')
      .col-md-2
        label Hora:
        br
        the-mask.hora(type='text', v-model='filtro.hora', placeholder='Hora', maxlength='5', :mask="['##:##']", required='')
      .col-md-2
        select-form(v-model='filtro.status', :value='filtro.status', name='Status', url='/status/agenda', id='nome', titulo='nome')
    .row
      .col-md-2
        select-form(v-model='filtro.areas', :value='filtro.areas', name='Área', url='/areas', id='id', titulo='title')
      .col-md-2
        select-form(v-model='filtro.usuario', :value='filtro.usuario', name='Usuário', url='/usuarios', id='name', titulo='name')
      .col-md-2
        label
        br
        .checkbox
          label
            input.ace(type='checkbox', @click='getVencidos',  :checked="vencidos", :value="vencidos", data-enpassusermodified="yes")
            span.lbl Agenda Vencidas
      .col-md-3
          label
          br
          .checkbox
            label(v-if="vencidos")
              | Qdt Agendas Vencidas:
              strong {{ total }}
      .col-md-1
        br
        button.btn.btn-primary(@click.prevent='getLimparFiltro') Limpar
  br
  kendo-datasource(ref="datasource1",
    :transport-read="{ url: urlGet, beforeSend: onBeforeSend }",
    :page-size='100',
    :server-paging="true",
    :schema-data="'data'",
    :schema-total="'total'",
    :schema-model="modelo",
    :filter="filter",
    :server-filtering="true",
    :schema-parse="parse")
  kendo-grid(:data-source-ref="'datasource1'",
    :pageable='true',
    :height="500",
    :pageable-always-visible="false")
    kendo-grid-column(:field="'cliente_nome'", :title="'Cliente'", :width='350', :locked='true',
                :lockable='false', :template="itemTemplate")
    kendo-grid-column(:field="'agenda_data'", :title="'Data'", :width='120', :format="'{0:dd/MM/yyyy}'")
    kendo-grid-column(:field="'status_nome'", :title="'Status'", :width='180', :template="statusTemplate")
    kendo-grid-column(:field="'area'", :title="'Áreas'", :width='180')
    kendo-grid-column(:field="'hora'", :title="'Hora'", :width='90', :format="'{0:HH:mm}'",)
    kendo-grid-column(:field="'datacad'", :title="'Data Cad'", :width='150', :format="'{0:dd/MM/yyyy HH:mm}'")
    kendo-grid-column(:field="'usuario_nome'", :title="'Usuário'", :width='300', :lockable="false")
    kendo-grid-column(:field="'cliente_nome'", :template="ACTIONTEMPLATE", :title="'Ações'", :width="145", :locked="true")
    // kendo-grid-column(:field="'id'", :template="ACTIONTEMPLATE", :title="'Ações'", :width="190", :locked="true")
</template>

<script>
import Vue from 'vue'
import '@progress/kendo-ui'
import '@progress/kendo-theme-default/dist/all.css'
import '@progress/kendo-ui/js/cultures/kendo.culture.pt-BR'
import '@progress/kendo-ui/js/messages/kendo.messages.pt-BR'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import { TheMask } from 'vue-the-mask'
import moment from 'moment'
import 'clockpicker/dist/jquery-clockpicker.min'
import 'clockpicker/dist/bootstrap-clockpicker.min.css'

import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import {
  DataSource,
  DataSourceInstaller
} from '@progress/kendo-datasource-vue-wrapper'
import SelectForm from '../../components/form/SelectForm'

import Template from '@/mixins/Template'
import Permission from '@/mixins/Permission'

Vue.use(GridInstaller)
Vue.use(DataSourceInstaller)
// eslint-disable-next-line no-undef
kendo.culture('pt-BR')
moment.locale('pt-BR')

export default {
  components: {
    Grid,
    DataSource,
    SelectForm,
    TheMask,
    flatPickr
  },
  mixins: [Template, Permission],
  watch: {
    'filtro.cliente' () {
      localStorage.setItem('_filtroAgenda', JSON.stringify(this.filtro))
    },
    'filtro.dataagenda' () {
      localStorage.setItem('_filtroAgenda', JSON.stringify(this.filtro))
    },
    'filtro.hora' () {
      localStorage.setItem('_filtroAgenda', JSON.stringify(this.filtro))
    },
    'filtro.status' () {
      localStorage.setItem('_filtroAgenda', JSON.stringify(this.filtro))
    },
    'filtro.areas' () {
      localStorage.setItem('_filtroAgenda', JSON.stringify(this.filtro))
    },
    'filtro.usuario' () {
      localStorage.setItem('_filtroAgenda', JSON.stringify(this.filtro))
    },
    '$route.query.vencidas' () {
      if(this.$route.query.vencidas) {
        this.vencidos = true
        this.$refs.datasource1.kendoDataSource.read({vencidos: true})
      } else {
        this.$refs.datasource1.kendoDataSource.read()
      }
    }
  },
  computed: {
    filter () {
      return {
        logic: 'and',
        filters: [
          {
            field: 'nome',
            operator: 'contains',
            value: this.filtro.cliente
          },
          {
            field: 'agenda_data',
            operator: 'contains',
            value: this.filtro.dataagenda
          },
          {
            field: 'hora',
            operator: 'contains',
            value: this.filtro.hora
          },
          {
            field: 'status_id',
            operator: 'contains',
            value: this.filtro.status
          },
          {
            field: 'area_id',
            operator: 'contains',
            value: this.filtro.areas
          },
          {
            field: 'usuario_id',
            operator: 'contains',
            value: this.filtro.usuario
          }
        ]
      }
    }
  },
  data () {
    return {
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altInput: true,
        altFormat: 'd F Y',
        dateFormat: 'Y-m-d',
        locale: Portuguese
      },
      total: 0,
      clientes: [],
      filtro: {
        cliente: null,
        dataagenda: null,
        hora: null,
        status: null,
        areas: null,
        usuario: null
      },
      modelo: {
        fields: {
          agenda_data: {
            type: 'date'
          },
          datacad: {
            type: 'date'
          },
          hora: {
            type: 'date'
          }
        }
      },
      vencidos: false,
      urlGet: `${this.$url}/agenda`
    }
  },
  methods: {
    getVencidos () {
      this.vencidos = !this.vencidos
      if(this.vencidos) {
        this.$refs.datasource1.kendoDataSource.read({vencidos: true})
      }else {
        this.$refs.datasource1.kendoDataSource.read()
      }
    },
    getLimparFiltro () {
      let filtro = {
        cliente: null,
        dataagenda: null,
        hora: null,
        status: null,
        areas: null,
        usuario: null
      }
      localStorage.setItem('_filtroAgenda', JSON.stringify(filtro))
      this.filtro = filtro
    },
    parse (d) {
      d.data.map(i => {
        i.cliente_nome = i.cliente_nome.toUpperCase()
        i.usuario_nome = i.usuario_nome.toUpperCase()
        return i
      })
      this.total = d.total || 0
      return d
    },
    onBeforeSend (xhr) {
      xhr.setRequestHeader(
        'Authorization',
        'Bearer ' + localStorage.getItem('token')
      )
    },
    handleClientes () {
      this.$axios('/clientes').then(resp => {
        this.clientes = resp.data
      })
    },
    itemTemplate (e) {
      var vm = this
      return {
        template: Vue.component('temp', {
          template: `
              <div style='height: 57px;'>
                <a :href='url(templateArgs.clienteid)' style='padding: 5px;display: block;color: #337ab7;' @click.prevent="buttonClick(templateArgs.clienteid)">
                  {{ templateArgs.cliente_nome }}
                </a>
              </div>
                `,
          data () {
            return {
              templateArgs: {}
            }
          },
          methods: {
            url (id) {
              return `/sistema/clientes/${id}`
            },
            buttonClick (id) {
              vm.$router.push(`/sistema/clientes/${id}`)
            }
          }
        }),
        templateArgs: e
      }
    },
    statusTemplate (e) {
      return {
        template: Vue.component('temp', {
          template: `
          <div style='height: 57px;'>
            <span style='display: block;padding: 5px' :style='[{ "background-color" : templateArgs.status_cor }, {color : getColorByBgColor(templateArgs.status_cor)}]'>{{ templateArgs.status_nome }}</span>
          </div>`,
          data () {
            return {
              templateArgs: {}
            }
          },
          methods: {
            hexToRgb (hex) {
              let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                hex
              )
              return result
                ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16)
                }
                : null
            },
            getColorByBgColor (hexcolor) {
              let r = this.hexToRgb(hexcolor).r
              let g = this.hexToRgb(hexcolor).g
              let b = this.hexToRgb(hexcolor).b
              let yiq = (r * 299 + g * 587 + b * 114) / 1000
              return yiq >= 128 ? 'black' : 'white'
            }
          }
        }),
        templateArgs: e
      }
    }
  },
  mounted () {
    if(this.$route.query.vencidas) {
      this.vencidos = true
      this.$refs.datasource1.kendoDataSource.read({vencidos: true})
    } else {
      this.$refs.datasource1.kendoDataSource.read()
    }
    // eslint-disable-next-line no-undef
    $('.hora')
      .clockpicker({
        placement: 'bottom',
        align: 'left',
        autoclose: true,
        default: 'now'
      })
      .on('change', e => {
        this.filtro.hora = e.target.value
      })
    let filtro = localStorage.getItem('_filtroAgenda')
      ? JSON.parse(localStorage.getItem('_filtroAgenda'))
      : null
    if (filtro) {
      this.filtro = filtro
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
